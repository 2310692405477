import React, { useState } from 'react';
import moment from 'moment';
import { validate as isValidUUID } from 'uuid';
import { DatePicker } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  NumericInput,
  Typography,
  IconButton,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  MoneyIcon,
  TrashIcon,
  AlertInfoIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';

const RentStep = ({
  id,
  idx,
  initialStartDate,
  initialEndDate,
  initialAmount,
  onChange,
  onDelete,
  priorRentStep,
  errors,
  leaseEndDate,
  renewalLeaseStartDate,
  renewalOngoing,
  canDelete,
  tooltipMessage,
  saving,
}) => {
  const today = moment();
  const [startDate, setStartDate] = useState(initialStartDate);
  const [amount, setAmount] = useState(initialAmount);

  const startDateIsInTheFuture = moment(startDate).isAfter(today);
  const isFirstRentStep = idx === 0;
  const disableDateEdit = !!startDate && !startDateIsInTheFuture;
  const rentStepIsSaved = isValidUUID(id);

  function handleChange(name, value) {
    if (name === 'date') {
      if (!moment(value).isValid()) {
        setStartDate(null);
        return;
      }
      const formattedValue = formatDateDB(value, false);
      setStartDate(formattedValue);
      onChange({ id, startDate: formattedValue, amount, name, idx });
    } else {
      setAmount(value);
      onChange({ id, startDate, amount: value, name, idx });
    }
  }

  const hasErrors = errors?.startDate || errors?.amount;
  const parsedAmount = disableDateEdit
    ? parseFloat(amount)?.toFixed(2)
    : amount;

  return (
    <>
      <Grid item xs={4}>
        {isFirstRentStep || rentStepIsSaved ? (
          <>
            <Typography
              variant="bodySmall"
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {formatDateDB(initialStartDate)}
              <Tooltip
                title={tooltipMessage}
                arrow
                placement="top"
                sx={{
                  display: 'flex',
                  marginLeft: '2 px',
                  marginRight: '5 px',
                  verticalAlign: 'middle',
                }}
                variant="default"
                hidden={disableDateEdit}
              >
                <AlertInfoIcon
                  sx={{ marginLeft: 0.5, marginTop: 0.5 }}
                  fontSize={'small'}
                  strokeWidth={0.5}
                />
              </Tooltip>
            </Typography>
          </>
        ) : (
          <DatePicker
            disabled={disableDateEdit}
            value={startDate}
            format="YYYY-MM-DD"
            placeholder="YYYY-MM-DD"
            disablePast
            shouldDisableDate={(date) => {
              const priorRentStepStartDate = moment(priorRentStep.startDate);
              const dateIsOnOrBeforeToday = date.isSameOrBefore(today);
              const dateIsOnOrBeforePreviousStepStartDate = date.isSameOrBefore(
                priorRentStepStartDate,
              );
              const dateIsAfterCurrentLeaseEndDate =
                date.isSameOrAfter(leaseEndDate);
              const dateIsBeforeRenewalLeaseStartDate = date.isBefore(
                renewalLeaseStartDate,
              );

              return (
                saving ||
                dateIsOnOrBeforePreviousStepStartDate ||
                dateIsOnOrBeforeToday ||
                (renewalOngoing &&
                  dateIsAfterCurrentLeaseEndDate &&
                  !dateIsBeforeRenewalLeaseStartDate)
              );
            }}
            onChange={(date) => handleChange('date', date)}
            error={hasErrors}
            helperText={errors?.startDate || null}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        <Typography
          variant="bodySmall"
          sx={{
            display: 'flex',
            alignItems: hasErrors ? 'flex-start' : 'center',
            paddingTop: hasErrors ? 1.5 : 0,
            height: '100%',
          }}
        >
          {!initialEndDate ? '---' : formatDateDB(initialEndDate)}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <NumericInput
          adornment={<MoneyIcon />}
          value={parsedAmount}
          disabled={disableDateEdit || saving}
          onChange={({ target: { value } }) => {
            handleChange('amount', value);
          }}
          error={hasErrors}
          helperText={errors?.amount || null}
          step="0.01"
        />
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
        {!disableDateEdit ? (
          <IconButton
            edge="start"
            variant={'delete'}
            disabled={saving || !canDelete}
            onClick={() => onDelete({ id, idx })}
          >
            <TrashIcon />
          </IconButton>
        ) : null}
      </Grid>
    </>
  );
};

export default RentStep;
