// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import {
  reduxForm,
  getFormSyncWarnings,
  initialize,
  getFormValues,
  getFormSyncErrors,
  getFormAsyncErrors,
} from 'redux-form';
import { pathOr, isNil, curryN, isEmpty } from 'ramda';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';

import {
  canGenerateLease,
  getLeaseDifference,
  getNonRentRecurringCharges,
  getTotalMonthlyCharges,
  parseRentValues,
} from '../../../utils/lease-helpers.js';
import type { GlobalState } from '../../App/types';

import LeaseRentAmountModal from './LeaseRentAmountModal/index.js';
import EditLeaseDataModal from './EditLeaseDataModal';
import EditRequiredLeaseFeesModal from './LeaseDataTabFormSections/EditRequiredLeaseFeesModal';
import { useAsyncMonthlyLeaseTransactions } from './hooks';
import { useAsyncEditMonthlyRequiredFees } from '../hooks';
import { promptToaster } from '../../../containers/App/actions';
import type { Props } from './types';
import validate from './validate';
import warn from './warn';
import { asyncValidate } from './asyncValidate';
import { withExpirationLimitsPropertyToggle } from '../../../hooks/data-fetching/useExpirationLimitsPropertyToggle';

/* Form Sections - In Order of Appearance */
import LeaseBasics from './LeaseDataTabFormSections/LeaseBasics';
import MonthlyLeaseCharges from './LeaseDataTabFormSections/MonthlyLeaseCharges';
import Reconciliation from './LeaseDataTabFormSections/Reconciliation';
import PetFeesAndRent from './LeaseDataTabFormSections/PetFeesAndRent';
import OneTimeLeaseChargesOrCredits from './LeaseDataTabFormSections/OneTimeLeaseCharges';
import Concessions from './LeaseDataTabFormSections/Concessions';
import EmergencyContactInformation from './LeaseDataTabFormSections/EmergencyContactInformation';
import MonthlyLeaseChargesOrCredits from './LeaseDataTabFormSections/MonthlyLeaseChargesOrCredits';
import LeaseSigningMethod from './LeaseDataTabFormSections/LeaseSigningMethod';
import LeaseActionButtons from './LeaseDataTabFormSections/LeaseActionButtons';

import {
  getIsSection236,
  getAffordableFloorPlanProgramTypes,
} from '../../../utils/affordable.js';
import LeasePacket from './LeaseDataTabFormSections/LeasePacket/index.js';
import { useQueryClient } from 'react-query';
import useCustomEvent from '../../../hooks/useCustomEvent';
import {
  LEASE_UPLOADED,
  LEASE_EXECUTED,
  LEASE_CANCELLED,
  VOUCHERS_UPDATED,
} from '../customEvents';

const getCompleteLeaseTermList = (
  leaseTermId: string,
  leaseTerms: Object[],
  softDeletedLeaseTerms: Object[] = [],
): Object[] => {
  const foundLeaseTerm = softDeletedLeaseTerms
    ? softDeletedLeaseTerms.find((lt) => lt.id === leaseTermId)
    : null;
  const isSelectedLeaseTermSoftDeleted = !isNil(foundLeaseTerm);

  return isSelectedLeaseTermSoftDeleted
    ? leaseTerms.concat({ ...foundLeaseTerm, disabled: true })
    : leaseTerms;
};

export const LeaseDataTabForm = (props: Props) => {
  const { open, openModal, closeModal } = useModal(false);
  const [modal, setModal] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const showLeaseDataModal = () => setModal('editLeaseDataModal');
  const showLeaseReqFeesModal = () => setModal('editLeaseRequiredFeesModal');
  const hideModals = () => setModal(null);

  const {
    actions,
    affordableValues,
    applicableRents,
    allAffordableTabsApproved,
    applicationId,
    basicLeaseFees,
    dirty,
    change,
    formSyncWarnings,
    formValues,
    frNames,
    handleSubmit,
    hasNonFR,
    intl,
    isDisabledMonthly,
    isEditable,
    labelEndDate,
    lateMethods,
    lease,
    leaseRentPercentage,
    leaseSignatureStatuses,
    leaseTerms,
    monthlyRecurringCharges,
    onGenerate,
    onSubmit,
    prospectInfo,
    residentId,
    securityDeposits,
    selectedMonthlyOption,
    selectedProperty,
    setFormDirty,
    transferInformation,
    unit,
    updateLeaseDate,
    valid,
    touch,
    softDeletedLeaseTerms,
    editFormValues,
    editLeaseFeesFormValues,
    updateRequiredFeesList,
    requiredFeesUpdated,
    asyncValidating,
    formAsyncErrors,
    formSyncErrors,
    noticeToVacate,
    flags,
  } = props;

  /**
   * We could move the ApplicantHeader and ResidentHeader components into the
   * LeaseDataTabForm folder, and have them called in this file in order to
   * avoid the need for this useEffect and to get rid of the `formDirty` state
   * in the parent component.
   */
  useEffect(() => {
    let subscribed = true;
    if (subscribed) {
      setFormDirty(dirty);
      if (!isEmpty(formSyncErrors)) {
        // Touch the error fields so they display the sync errors on load.
        touch('FormLeaseDataTab', Object.keys(formSyncErrors));
      }
    }
    return () => {
      subscribed = false;
    };
    // eslint-disable-next-line
  }, [dirty, setFormDirty]);

  const queryClient = useQueryClient();
  const shouldDisplayLeasePacket = flags?.documentManagementMvp ?? false;
  const section236Flag = flags?.section236;
  const floorPlan = unit?.floorPlan;
  const isSection236 =
    section236Flag && getIsSection236(selectedProperty, floorPlan);
  const { isHUDFloorPlan, isRDFloorPlan } =
    getAffordableFloorPlanProgramTypes(floorPlan);
  const {
    marketRent,
    quotingRent,
    basicRent,
    noteRent,
    hud236BasicRent,
    hud236MarketRent,
  } = parseRentValues(affordableValues, unit, lease, isSection236);
  const nonOptionalChargessFeature = flags?.nonOptionalChargessFeature ?? false;
  const nonOptionalCharge = nonOptionalChargessFeature
    ? affordableValues?.nonOptionalCharge
    : 0;
  const {
    submitUpdateMonthlyRequiredFees,
    isSubmitting: isSubmittingUpdateFees,
  } = useAsyncEditMonthlyRequiredFees(
    pathOr('', ['organizationId'], selectedProperty),
    pathOr('', ['id'], selectedProperty),
    pathOr('', ['id'], lease),
    updateRequiredFeesList,
    actions.promptToaster,
  );
  const refreshData = () => {
    queryClient.invalidateQueries([
      'household',
      'lease',
      'validateTransferLease',
    ]);
  };
  const handleFormSubmit = (submitParams: any) => {
    setSubmitting(true);
    onSubmit(submitParams, refreshData);
  };

  const handleLeaseDataModalSubmit = (editValues: Object) => {
    const { formValues } = props;
    const updatedLease = {
      ...formValues,
      startDate: editValues.editLeaseStartDate,
      endDate: editValues.editLeaseEndDate,
      leaseTermId: editValues.editLeaseTerm,
      leasedRent: editValues.editLeaseRentAmount,
      isReceivingAssistance: editValues.editIsReceivingAssistance,
      overrideLeaseExpirationLimit: editValues.overrideLeaseExpirationLimit,
    };

    hideModals();
    onSubmit(updatedLease);
  };

  const handleReceivingAsstSubmit = (value: boolean) => {
    const { formValues } = props;
    const updatedLease = {
      ...formValues,
      isReceivingAssistance: value,
    };

    onSubmit(updatedLease);
  };

  const handleLeaseRequiredFeesSubmit = async (feesToRemove: Array<Object>) => {
    const { monthlyReqFees } = editLeaseFeesFormValues;
    const parseFormRequiredFee = (reqFee, removed) => {
      const isNumerical = reqFee.type === 'numeric';
      return {
        id: reqFee.id,
        ptc: reqFee.ptc,
        leaseFeeAmount: isNumerical ? Number(reqFee.value) : 0,
        descriptiveFeeAmount: isNumerical ? null : reqFee.descriptiveValue,
        isRemoved: removed,
      };
    };
    const editedFees = monthlyReqFees.map((fee) =>
      parseFormRequiredFee(fee, false),
    );
    const removedFees = feesToRemove.map((fee) =>
      parseFormRequiredFee(fee, true),
    );
    await submitUpdateMonthlyRequiredFees({
      removedMonthlyFees: removedFees,
      requiredMonthlyFees: editedFees,
    });

    hideModals();
  };
  const handleLeaseRequiredFeesSubmitCurried = curryN(
    2,
    handleLeaseRequiredFeesSubmit,
  );

  const { allMonthlyTransactions, updateTransactionsList } =
    useAsyncMonthlyLeaseTransactions(lease.id);

  useCustomEvent({
    eventKey: LEASE_UPLOADED,
    listener: () => {
      updateTransactionsList();
    },
  });

  useCustomEvent({
    eventKey: VOUCHERS_UPDATED,
    listener: () => {
      updateTransactionsList();
    },
  });

  useCustomEvent({
    eventKey: LEASE_EXECUTED,
    listener: () => {
      updateTransactionsList();
    },
  });

  useCustomEvent({
    eventKey: LEASE_CANCELLED,
    listener: () => {
      updateTransactionsList();
    },
  });

  const activeMonthlyRecurringFees = monthlyRecurringCharges.filter(
    (fee) => !fee.isRemoved,
  );
  const totalMonthlyCharges = getTotalMonthlyCharges(
    allMonthlyTransactions,
    lease,
  );

  const nonRentCharges = getNonRentRecurringCharges(activeMonthlyRecurringFees);

  const applicableNonRentCharges = nonRentCharges;
  const difference = getLeaseDifference(
    pathOr(0, ['leasedRent'], formValues),
    applicableNonRentCharges,
    totalMonthlyCharges,
  );

  const currentLeaseSelected = residentId && pathOr(null, ['isMovedIn'], lease);

  const editLeaseDataModalInitial = {
    editLeaseTerm: pathOr(null, ['leaseTermId'], formValues),
    editLeaseStartDate: pathOr(null, ['startDate'], formValues),
    editLeaseEndDate: pathOr(null, ['endDate'], formValues),
    editLeaseRentAmount: pathOr(null, ['leasedRent'], formValues),
    editIsReceivingAssistance: pathOr(
      null,
      ['isReceivingAssistance'],
      formValues,
    ),
    overrideLeaseExpirationLimit: !isNil(
      formValues?.overrideLeaseExpirationLimitUpdatedAt,
    ),
  };
  const editLeaseRequiredFeesInitial = monthlyRecurringCharges.map(
    (fee, idx) => ({
      ptc: fee.ptc,
      type: isNil(fee.descriptiveValue) ? 'numeric' : 'descriptive',
      descriptiveValue: fee.descriptiveValue,
      value: fee.value,
      id: fee.id,
      isRemoved: fee.isRemoved,
      idx,
      label: fee.label,
    }),
  );

  const canComplete = canGenerateLease(formSyncWarnings, valid);
  const isRenewalComplete = pathOr(false, ['isRenewalComplete'], lease);
  const receivingAssistance = pathOr(
    null,
    ['isReceivingAssistance'],
    formValues,
  );

  const leaseBasicsLeaseTerms = getCompleteLeaseTermList(
    pathOr('', ['leaseTermId'], formValues),
    leaseTerms,
    softDeletedLeaseTerms,
  );
  const editModalLeaseTerms = getCompleteLeaseTermList(
    pathOr('', ['editLeaseTerm'], editFormValues),
    leaseTerms,
    softDeletedLeaseTerms,
  );

  return (
    <React.Fragment>
      {lease && (
        <LeaseRentAmountModal
          open={open}
          closeModal={closeModal}
          leaseRentPercentage={leaseRentPercentage}
          quotingRent={quotingRent}
          unit={unit}
          receivingAssistance={receivingAssistance}
          affordableValues={affordableValues}
          applicableRents={applicableRents}
          lease={lease}
          hud236BasicRent={isSection236 ? hud236BasicRent : null}
          hud236MarketRent={isSection236 ? hud236MarketRent : null}
          property={selectedProperty}
          intl={intl}
          renewalLeaseStartDate={props?.initialValues?.renewalLeaseStartDate}
          renewalIsRenewalComplete={
            props?.initialValues?.renewalIsRenewalComplete
          }
          refreshLDT={(assistanceValue) => {
            refreshData();
            updateTransactionsList();
            handleReceivingAsstSubmit(assistanceValue);
          }}
        />
      )}
      <EditLeaseDataModal
        show={modal === 'editLeaseDataModal'}
        onSubmit={handleLeaseDataModalSubmit}
        dismiss={hideModals}
        leaseTerms={editModalLeaseTerms}
        leaseRentPercentage={leaseRentPercentage}
        quotingRent={quotingRent}
        initialValues={editLeaseDataModalInitial}
        unit={unit}
        intl={intl}
        receivingAssistance={receivingAssistance}
        affordableValues={affordableValues}
        applicableRents={applicableRents}
        lease={lease}
      />
      <EditRequiredLeaseFeesModal
        intl={intl}
        show={modal === 'editLeaseRequiredFeesModal'}
        initialValues={{ monthlyReqFees: editLeaseRequiredFeesInitial }}
        dismiss={hideModals}
        leaseRequiredFees={monthlyRecurringCharges}
        leaseIsSaved={!!lease.id}
        handleSaveFees={handleLeaseRequiredFeesSubmitCurried}
        isSubmitting={isSubmittingUpdateFees}
      />

      <LeaseBasics
        basicLeaseFees={basicLeaseFees}
        canComplete={canComplete}
        currentLeaseSelected={currentLeaseSelected}
        formValues={formValues || {}}
        intl={intl}
        isEditable={isEditable}
        labelEndDate={labelEndDate}
        lateMethods={lateMethods}
        lease={lease}
        residentId={residentId}
        transferInformation={transferInformation}
        showLeaseDataModal={showLeaseDataModal}
        unit={unit}
        leaseTerms={leaseBasicsLeaseTerms}
        updateLeaseDate={updateLeaseDate}
        formAsyncErrors={formAsyncErrors}
        selectedProperty={selectedProperty}
        promptToaster={actions.promptToaster}
        noticeToVacate={noticeToVacate}
      />
      <Row>
        <MonthlyLeaseCharges
          monthlyRecurringCharges={activeMonthlyRecurringFees}
          intl={intl}
          isAffordable={affordableValues.isAffordable}
          isEditable={isEditable}
          marketRent={marketRent}
          leaseRentPercentage={leaseRentPercentage}
          nonRentCharges={applicableNonRentCharges}
          quotingRent={quotingRent}
          noteRent={noteRent}
          basicRent={basicRent}
          affordableValues={affordableValues}
          applicableRents={applicableRents}
          showEditLink={!!(residentId && currentLeaseSelected)}
          showLeaseDataModal={
            flags?.automateMonthlyLeaseTransactionsMvp
              ? openModal
              : showLeaseDataModal
          }
          showLeaseReqFeesModal={showLeaseReqFeesModal}
          isDisabledMonthly={isDisabledMonthly}
          lease={lease}
          canComplete={canComplete}
          changeFn={change}
          selectedProperty={selectedProperty}
          nonOptionalCharge={nonOptionalCharge}
          isSection236={isSection236}
          isHUD={isHUDFloorPlan}
          isRD={isRDFloorPlan}
          hud236BasicRent={isSection236 ? hud236BasicRent : null}
          hud236MarketRent={isSection236 ? hud236MarketRent : null}
        />
        <Reconciliation
          formValues={formValues}
          difference={difference}
          intl={intl}
          nonRentCharges={applicableNonRentCharges}
          totalMonthlyCharges={totalMonthlyCharges}
        />
      </Row>
      <div className="divider" />
      <PetFeesAndRent intl={intl} isEditable={isEditable} />
      <div className="divider" />
      <OneTimeLeaseChargesOrCredits
        intl={intl}
        isEditable={isEditable}
        securityDeposits={securityDeposits}
      />
      <Concessions intl={intl} isEditable={isEditable} />
      <EmergencyContactInformation intl={intl} isEditable={isEditable} />
      <div className="divider" />
      <MonthlyLeaseChargesOrCredits
        applicationId={applicationId}
        allMonthlyTransactions={allMonthlyTransactions}
        canComplete={canComplete}
        frNames={frNames}
        intl={intl}
        isDisabledMonthly={isDisabledMonthly}
        isLocked={lease.isLocked}
        labelEndDate={labelEndDate}
        lease={lease}
        formValues={formValues}
        residentId={residentId}
        selectedMonthlyOption={selectedMonthlyOption}
        valid={valid}
        updateTransactionsList={updateTransactionsList}
        showGenerateMessage={!residentId || lease.isRenewal || lease.isTransfer}
      />
      <div className="divider" />
      {shouldDisplayLeasePacket && (
        <>
          <LeasePacket
            leaseId={lease?.id}
            isLeaseLocked={lease?.isLocked}
            isAffordable={floorPlan?.isAffordable}
            isCommercial={floorPlan?.isCommercial}
          />
          <div className="divider" />
        </>
      )}
      <LeaseSigningMethod
        hasNonFR={hasNonFR}
        isEditable={isEditable}
        lease={lease}
        selectedProperty={selectedProperty}
      />
      {(!residentId || lease.isRenewal || lease.isTransfer) && (
        <LeaseActionButtons
          allAffordableTabsApproved={allAffordableTabsApproved}
          applicationId={applicationId}
          canComplete={canComplete}
          difference={difference}
          handleSubmit={handleSubmit}
          hasNonFR={hasNonFR}
          intl={intl}
          isRenewalComplete={isRenewalComplete}
          lease={lease}
          leaseSignatureStatuses={leaseSignatureStatuses}
          onGenerate={onGenerate ? handleSubmit(onGenerate) : null}
          onSubmit={handleFormSubmit}
          prospectId={prospectInfo.id}
          residentId={residentId}
          submitting={submitting}
          asyncValidating={asyncValidating}
          requiredFeesUpdated={requiredFeesUpdated}
        />
      )}
    </React.Fragment>
  );
};

export const mapStateToProps = (state: GlobalState): Object => {
  const { app } = state;

  return {
    formSyncWarnings: getFormSyncWarnings('FormLeaseDataTab')(state),
    formValues: getFormValues('FormLeaseDataTab')(state),
    editFormValues: getFormValues('editLeaseDataForm')(state),
    editLeaseFeesFormValues: getFormValues('editRequiredLeaseFeesForm')(state),
    selectedProperty: app.selectedProperty,
    formAsyncErrors: getFormAsyncErrors('FormLeaseDataTab')(state),
    formSyncErrors: getFormSyncErrors('FormLeaseDataTab')(state),
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}
export default withLDConsumer()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withExpirationLimitsPropertyToggle(
      reduxForm({
        form: 'FormLeaseDataTab',
        onSubmitSuccess(result, dispatch) {
          dispatch(initialize('FormLeaseDataTab', result));
        },
        enableReinitialize: true,
        validate,
        warn,
        asyncValidate: asyncValidate(),
        asyncChangeFields: [
          'overrideLeaseExpirationLimit',
          'endDate',
          'startDate',
          'leaseTermId',
        ],
        asyncBlurFields: [
          'overrideLeaseExpirationLimit',
          'endDate',
          'startDate',
          'leaseTermId',
        ],
      })(LeaseDataTabForm),
    ),
  ),
);
